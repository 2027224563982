import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError , switchMap, map } from 'rxjs/operators';
import { ProfessionalService } from 'src/app/shared/services';
import * as professionalsActions from 'src/app/store/actions/professionals/professionals-actions';

@Injectable()

export class ProfessionalsJsonEffects {

    constructor( 
        private action$ :                   Actions,
        private professionalService:        ProfessionalService
    ){}

    loadDocuments$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(professionalsActions.getProfessionalsJsonAction),
            switchMap( () =>
                {
                    return this.professionalService.GetProfessionalsJson()
                    .pipe(
                        map(professionalsJSONres => 
                        { 
                            return professionalsActions.getProfessionalsJsonActionSuccess({professionalsJSONres}) 
                        }),
                        
                        catchError(error => {
                            // this.componentToSpinnerService.sendMessage('hide');
                            return of( professionalsActions.getProfessionalsJsonActionFailure({ error : error }) /* getPrescriptionsFailure({ error : error }) */ )
                        })
                    )

                }
            )
        )
    })
}
