import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { UserInfo } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})

export class CheckUserRoleService {

  constructor(
    private authService:                                     MsalService
  ) { }

  CheckUserRole():{isViewer:boolean,isDoctor:boolean,isViewerGermany:boolean} | undefined{
    
    const userInfo: UserInfo | AccountInfo = this.authService.instance.getAllAccounts()[0];
    
    let userRoles : {isViewer:boolean,isDoctor:boolean,isViewerGermany:boolean} = {
      isViewer : false,
      isViewerGermany : false,
      isDoctor : false
    }

    if(userInfo){

      const roles = userInfo.idTokenClaims?.roles;

      if( roles ){

        /* test solo doctor sacamos role de enfermero y admin */
        /* let testGroups = roles.filter((e)=>e!=='viewer' && e!=='viewer.germany');
        if( !testGroups.find((e) => e === 'doctor') )testGroups.push('doctor'); */
    
        //test solo viewer , del array role doctor y viewer.germany
        /* let testGroups = roles.filter((e)=>e!=='viewer.germany' && e!=='doctor');
        if( !testGroups.find((e) => e === 'viewer') )testGroups.push('viewer');  */
        
        /* test solo viewer.germany , del array role doctor y admin */
        /* let testGroups = roles.filter((e)=>e!=='doctor' && e!=='viewer');
        if( !testGroups.find((e) => e === 'viewer.germany') )testGroups.push('viewer.germany'); */
    
        (roles as string[])/* testGroups */.find((e : string) => e === 'viewer' ) ? userRoles.isViewer = true : userRoles.isViewer = false;
        (roles as string[])/* testGroups */.find((e : string) => e === 'doctor') ? userRoles.isDoctor = true : userRoles.isDoctor = false;
        (roles as string[])/* testGroups */.find((e : string) => e === 'viewer.germany') ? userRoles.isViewerGermany = true : userRoles.isViewerGermany = false;
    
      
        return userRoles;
      }else {
        userRoles = { ...userRoles , isViewer : false , isDoctor : false , isViewerGermany : true };
        return userRoles
      }

      
    } else {
      return undefined;
    }
    
  }
}
