import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { VideoFiltersCheckBoxes } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class VideosFiltersService {

  /* 
  * comunica searcher component con el hijo filters
  * searcher le pasa el objeto con los filtros a pintar
  * e filters se subscribe a esta observable para ver los cambios
  */

  private subject = new Subject<VideoFiltersCheckBoxes>();

  sendMessage(videos_filters: VideoFiltersCheckBoxes) {
    this.subject.next( videos_filters );
  }

  clearMessages() {
    this.subject.next( new VideoFiltersCheckBoxes() );
  }

  onMessage(): Observable<VideoFiltersCheckBoxes> {
    return this.subject.asObservable();
  }
}
