import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableToTablePaginationService {

  private subject = new Subject<number>();
  private subjectTotalElementos = new Subject<number>();

  /* 
  * método que envía first=1 a table-pagination.component 
  * cuando hay cambios en los filtros
  */
  sendMessage( message:number) {
    this.subject.next(message);
  }

  clearMessages() {
    this.subject.next(0);
  }

  onMessage(): Observable<number> {
    return this.subject.asObservable();
  }

  /* 
  * método que envía el total de elementos
  * retornados por la api
  */
  sendTotalElementos(message:number){
    this.subjectTotalElementos.next(message);
  }

  clearTotalElementos(){
    this.subjectTotalElementos.next(0);
  }

  onTotalElementos():Observable<number>{
    return this.subjectTotalElementos.asObservable();
  }
}
