import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableToPopupService {

  private subject = new Subject<string>();
  private closeSubject = new Subject<string>();

  sendMessage( documentId : string /* videoParams:Document */) {
    this.subject.next( documentId /* { videoParams } */);
  }

  clearMessages() {
    this.subject.next('');
  }

  onMessage(): Observable<string> {
    return this.subject.asObservable();
  }


  sendCloseOrder(message:string):void{
    this.closeSubject.next(message);
  }

  clearCloseMessages() {
    this.closeSubject.next('');
  }

  onCloseMessage(): Observable<string> {
    return this.closeSubject.asObservable();
  }
}
