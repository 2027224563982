import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
// import { VideoFilterObj } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})

export class FiltersToTableService {

  private subject = new Subject<object>();
  private subjectOrder = new Subject<object>();
  private enableFiltersSubject = new Subject<string>();

  /* sendMessage(videos_filters: VideoFilterObj) {
    this.subject.next({ videos_filters });
  } */

  clearMessages() {
    this.subject.next({});
  }

  onMessage(): Observable<object> {
    return this.subject.asObservable();
  }

  sendOrder(message:object){
    this.subjectOrder.next({message});
  }

  clearOrder() {
    this.subjectOrder.next({});
  }

  onOrder(){
    return this.subjectOrder.asObservable();
  }

  sendEnableFiltersOrder(message:string){
    this.enableFiltersSubject.next(message);
  }

  cleadEnableFiltersOrder() {
    this.enableFiltersSubject.next('');
  }

  onEnableFiltersOrder(){
    return this.enableFiltersSubject.asObservable();
  }
}
