import { createAction , props } from '@ngrx/store';
import { UserInfo } from '../../models';

export const addUser = createAction('Add User' , props<UserInfo>());
export const removeUser = createAction('Remove User' , props<UserInfo>())
export const clearUser = createAction('Clear User');
export const returnUsers = createAction('Return User');

export const addProfessionalLoggedIdToUser = createAction(
    '[User] add professionalLoggedId to user' , 
    props<{ professionalLoggedId : string }>()
);

export const addProfessionalAvatar = createAction(
    '[User] add professional avatar to user',
    props<{ avatar : string }>()
)