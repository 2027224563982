import { createReducer , on } from '@ngrx/store';
import { DocumentRes } from 'src/app/models';
import * as documentsActions from 'src/app/store/actions/documents/documents.actions';
import * as commentsActions from 'src/app/store/actions/documents/comments.actions';

export interface DocumentsState {
    documents : DocumentRes
}

const initialState : DocumentRes = new DocumentRes();

export const DocumentsReducer = createReducer(
    initialState,
    on( documentsActions.getDocumentsSuccess , (state , {myDocumentRes}  ) => {

        const itemsWithComplications = myDocumentRes.items.map((d) => {
            return d.complications ? d : { ...d , complications : [] };
        })

        state = { ...myDocumentRes , items : itemsWithComplications}
        return  {...state , action:'get_new_documents'} ;
        
    }) ,
    
    on( documentsActions.getDocumentsFailure , (state , {error}  ) => {

        return  { ...state , error:error } ;
        
    }) ,

    on( documentsActions.modifyFavoriteProperty ,( state , { params } ) => {

        const { favorite } = params || {};

        const myVideos = state.items.map(( v ) => { return v.id === params.Id ? { ...v , favorite : favorite } : v } );
        
        return {...state , Value : myVideos};
    }) , 

    on( documentsActions.addSasUrlPropertySuccess ,( state , { item } ) => {

        if(item){
            const { videoId , sasUrl } = item || {};
            const myDocuments = state.items.map((d) => {
                return d.id === videoId ? { ...d , sasUri : sasUrl } : { ...d };
            })
            return { ...state , items : myDocuments };
        } else {
            return state;
        }
        
    }) ,

    on( documentsActions.setLikePropertyTrueSuccess ,( state , { documentId } ) => {
        const myVideos = state.items.map(( v ) => { return v.id === documentId ? { ...v , isLike : true } : v } );
        return { ...state , items : myVideos };
    }) , 

    on( documentsActions.setLikePropertyFalseSuccess ,( state , { documentId } ) => {
        const myVideos = state.items.map(( v ) => { return v.id === documentId ? { ...v , isLike : false } : v } );
        return { ...state , items : myVideos };
    }) ,

    on( documentsActions.addFeaturePropertyToDocumentSuccess ,( state , { documentId } ) => {

        if( documentId ){
            const myVideos = state.items.map(( v ) => { return v.id === documentId ? { ...v , isFeatured : true } : v } );
            return { ...state , items : myVideos };
        } else {
            return state;
        }
        
    }) ,

    on( documentsActions.removeFeaturePropertyToDocumentSuccess ,( state , { documentId } ) => {

        if( documentId ){
            const myVideos = state.items.map(( v ) => { return v.id === documentId ? { ...v , isFeatured : false } : v } );
            return { ...state , items : myVideos };
        } else {
            return state;
        }
        
    }) ,

    on( documentsActions.addCommentsToDocumentsSuccess , ( state , { myCommentsRes } ) => {
     
        const { documentId , items } = myCommentsRes || {};

        const myDocuments = state.items.map((d) => {
            return d.id === documentId ? { ...d , comments : items } : { ...d };
        });
        
        return { ...state , items : myDocuments , action : 'add_comment' };
    }) ,
  
    on( commentsActions.addCommentToDocumentSuccess , ( state , { myCommentWithId } ) => {
        if(myCommentWithId){


            const { videoId } = myCommentWithId || {};

            const myDocuments = state.items.map(( d ) => {

                if(d.id === videoId && d.comments){
                    return d.id === videoId ? { ...d , totalComments : d.totalComments + 1 , comments : [ ...d.comments , myCommentWithId ] } : { ...d };
                } else {
                    return {...d};
                }

                
            })
    
            return { ...state , items : myDocuments };
        } else {
            return state;
        }

        
    }) ,

    on( commentsActions.addCommentToDocumentFailure , ( state /* , { addCommentError } */ ) => {

        return state;

        /* console.log('entra por aquí al intentar insertar nuevo comentario');
        const { documentId , error } = addCommentError || {};
        let comment : CommentToAddClass = new CommentToAddClass();

        const myDocuments = state.items.map(( d ) => {
            return d.id === documentId ? { ...d , comments : [ ...d.comments , comment ] } : { ...d };
        })

        return { ...state , items : myDocuments }; */

    }) ,

    on( commentsActions.removeCommentToDocumentSuccess , ( state , { comment } ) => {

        if(comment){
            const { videoId } = comment || {};

            const myDocuments = state.items.map(( d ) => {

                if( d.id === videoId && d.comments ){
                    return { ...d , totalComments : d.totalComments - 1 , comments : d.comments.filter((d) => d.id !== comment.id) };
                }else {
                    return { ...d };
                }
            })

            return { ...state , items : myDocuments };
        } else {
            return state;
        }
    }) 
)
