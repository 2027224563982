export const environment = {
  production: false,

  environmentProtocol:'https://',
  environmentDomain: window.location.hostname === 'localhost' ? 'http://localhost:4201' : '-dev.clinicabaviera.com',

  mediaService: {
    api: {
      baseUrl: 'https://func-media-dev-westeu.azurewebsites.net/api/SearchBlob',
      url: 'https://func-media-dev-westeu.azurewebsites.net/api/',
      key:'UvoaKwKGj0-HBmr-TugP9JKywttzWfubCx330Wje8x7UAzFuDdqCyQ==',
      keySegment:'code=UvoaKwKGj0-HBmr-TugP9JKywttzWfubCx330Wje8x7UAzFuDdqCyQ=='
    },

    clientId: '2e4536eb-c836-4349-aada-36eb8abab5f8', 
    authority: 'https://login.microsoftonline.com/cb4bec43-26c0-4837-bf6c-ab98d284af38',
    redirectUri: '/',
    scopes: [
      'profile',
      'openid',
      'api://2e4536eb-c836-4349-aada-36eb8abab5f8/player',
    ]
  },

  usersService : {
    url: 'https://app-users-dev-westeu.azurewebsites.net/api/',
    clientId: '05a35cc1-faee-44e0-b1e4-0cff3f341f0d',
    scopes : [
      '05a35cc1-faee-44e0-b1e4-0cff3f341f0d/users',
      'profile',
      'openid'
    ]
  }
};

/* export const environment = {
  production: false,

  environmentProtocol:'https://',
  environmentDomain:'-pre.clinicabaviera.com',
 
  mediaService : {
    api: {
      baseUrl: 'https://func-media-pre-westeu.azurewebsites.net/api/SearchBlob',
      url: 'https://func-media-pre-westeu.azurewebsites.net/api/',
      key: '5zdw-aDKuN_wDAx7HmjR2EWEI--M7Aw581UB3LGsjnnGAzFuj9xCyw==',
      keySegment: 'code=5zdw-aDKuN_wDAx7HmjR2EWEI--M7Aw581UB3LGsjnnGAzFuj9xCyw=='
    },

    clientId: '2e4536eb-c836-4349-aada-36eb8abab5f8', 
    authority: 'https://login.microsoftonline.com/cb4bec43-26c0-4837-bf6c-ab98d284af38',

    redirectUri: '/',
    scopes: [
      'profile',
      'openid',
      'api://2e4536eb-c836-4349-aada-36eb8abab5f8/player',
    ]
  },

  usersService : {
    url: 'https://app-users-pre-westeu.azurewebsites.net/api/',
    clientId: '05a35cc1-faee-44e0-b1e4-0cff3f341f0d',
    scopes : [
        '05a35cc1-faee-44e0-b1e4-0cff3f341f0d/users',
        'profile',
        'openid'
    ]
  }
  
}; */

