export { UserInfo , UserRes } from 'src/app/models/interfaces/user';
export { Pending } from 'src/app/models/interfaces/http';
// export { VideoRes , VideoFilterObj , Video } from 'src/app/models/interfaces/documents/video';
// export { VideoFilterObj } from 'src/app/models/class/documents/documentsFiltersClass.models';
export { VideoFiltersCheckBoxes } from 'src/app/models/class/videoFiltersCheckBoxes';
export { AppState } from 'src/app/models/interfaces/appState.models';
// export { Document , DocumentRes } from 'src/app/models/interfaces/documents/documents.models';
export { Document , DocumentRes /* , CommentRes */ } from 'src/app/models/class/documents/documentsClass.models';
export { DocumentsFilters } from 'src/app/models/class/documents/documentsFiltersClass.models';
export { PaginationParams } from 'src/app/models/interfaces/paginationParams.models';
export { DocumentSasUrlRes } from 'src/app/models/class/documents/documentSasUrlClass.models';
export { Item } from 'src/app/models/class/documents/documentSasUrlClass.models';
/* comments */
export {  } from 'src/app/models/class/documents/'
export { CommentToAddClass , CommentAddedRes , Message , CommentsResClass } from 'src/app/models/class/documents/commentClass.models';
// export { CommentsRes , Comment , Message } from 'src/app/models/interfaces/documents/documents.comments';
/* professionals */
// export { ProfessionalsJSON , ProfessionalsJSONRes ,Payload } from 'src/app/models/interfaces/professionals/professionalsJson';
export { ProfessionalsJSONRes , ProfessionalFromJson } from 'src/app/models/class/professionals/professionalsJsonClass.models';
/* clinics */
export { ClinicForStreming } from 'src/app/models/class/clinics/clinics.models';

