import { Component , HostListener, OnInit , OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthComponent } from './auth/auth.component';
import { fas, faFolderOpen, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary  } from '@fortawesome/angular-fontawesome';
import { AppState, ProfessionalsJSONRes, UserInfo, UserRes } from './models';
import { Store } from '@ngrx/store';
import { ProfessionalService, TopMenuToStreamingComponentService } from './shared/services';
import { getProfessionalsJsonAction } from './store/actions/professionals/professionals-actions';
import { addProfessionalAvatar, addUser } from './store/actions/user-actions';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentsHelperService } from './documents/services';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AuthComponent]
})
export class AppComponent implements OnInit , OnDestroy {

  // title :                                                                   string = 'player';
  propertyForTesting=                                                       '';                                                       
  isIframe:                                                                 boolean = false;
  isViewer:                                                                 boolean = true;
  logged :                                                                  boolean = false;
  userActivity:                                                             number = 0;
  userInactive:                                                             Subject<string> = new Subject();
  tokenInterval:                                                            number = 0;
  faFolderOpen:                                                             IconDefinition = faFolderOpen;
  userDestroyed$:                                                           Subject<void> = new Subject<void>();
  professionalsJsonDestroyed$:                                              Subject<void> = new Subject<void>();
  activePath:                                                               string = '';
  userLogged:                                                               UserInfo = {};   
  professionalsFromGoingUp:                                                 ProfessionalsJSONRes;                                                    

  constructor( 
    library:                                                                FaIconLibrary ,
    private authComponent :                                                 AuthComponent ,
    private store :                                                         Store<AppState>,
    private professionalService:                                            ProfessionalService,
    private router:                                                         Router,
    private route:                                                          ActivatedRoute,
    private topMenuToStreamingComponentService:                             TopMenuToStreamingComponentService,
    private commentsHelperService:                                          CommentsHelperService
  ){
    library.addIconPacks(fas);
    library.addIcons(faFolderOpen);
    //this.SetTimeout();
    this.userInactive.subscribe(() => console.log('user has been inactive for 2hours'));
  
  }

  ngOnInit(): void {
    this.GetUserLogged();   
    this.StoreDoctorsJson();
    this.CheckCurrentPath();
  }

  CheckCurrentPath():void{
    this.activePath = window.location.pathname ;
  }

  GetUserLogged():void{

    this.store.select(appState => appState.User)
    .pipe(takeUntil( this.userDestroyed$ ))
    .subscribe((user) => {

      this.userLogged = user;
      const { idTokenClaims , username , code } = user || {};
      username ? this.logged = true : this.logged = false;

      if(idTokenClaims){

        const { roles } = idTokenClaims || {};

        if(roles?.find((r) => r === 'viewer' || r === "viewer.germany" || r === 'doctor')){

          this.isViewer = true;

        } else {

          this.isViewer = false;
          
          setTimeout(() => {
            this.authComponent.Logout();
          }, 10000);
        }
      }

      if( username && !code ){
        this.professionalService.LoadUserByEmail(username).subscribe((res : UserRes)=>{
          
          if( res ) {
            const {role , clinics , documentNumber , phoneNumber , gender , active , id , userCode , professionalClinics} = res;
  
            const info = {
              ...user ,userRole:role ,clinics : clinics , documentNumber: documentNumber ,
              phoneNumber : phoneNumber , gender : gender , active : active , professionalLoggedId : id , professionalClinics : professionalClinics,
              code : userCode ? userCode : 'professional_without_user_code' 
            };
            
            
            this.store.dispatch(addUser(info));
          }
  
        },(error: HttpErrorResponse) => {
          console.log(error);
        })
      }
    })
  }

  StoreDoctorsJson():void{
    this.store.select( AppState => AppState.professionalsJSON ).pipe(takeUntil( this.professionalsJsonDestroyed$ ))
    .subscribe(( professionalsJson : ProfessionalsJSONRes ) => {
      this.professionalsFromGoingUp = professionalsJson;
      const { payload } = this.professionalsFromGoingUp || {};

      if(payload.length < 1){
        this.store.dispatch( getProfessionalsJsonAction({ professionalsFilters : {} }) );
      } else {

        const { avatar , username } = this.userLogged || {};
        if(!avatar && username){

          const userAvatar = this.commentsHelperService.AddAvatarByEmail( username , payload );

          this.store.dispatch(addProfessionalAvatar({ avatar : userAvatar }));
        }
      }
    })
  }

  RecibeFrame(iframe:boolean){
    this.isIframe = iframe;
  }

  isLogged(e:boolean):void{
    this.logged = e;
  }

  /* SetTimeout() {
    this.userActivity = window.setTimeout(() => this.authComponent.Logout(),900000);
  } */

  GotoPage(page : string):void{
    this.activePath = page;
    
    if(page === '/documents'){
      /* stop streaming before leave */
      this.topMenuToStreamingComponentService.sendMessage('stop_video');
    }
    this.router.navigate([page]);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    //this.SetTimeout();
  }

  ngOnDestroy(): void {
    this.professionalsJsonDestroyed$.next();
    this.professionalsJsonDestroyed$.complete();
    this.userDestroyed$.next();
    this.userDestroyed$.complete();
  }

}
