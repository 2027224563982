import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CommentsResClass, Item, DocumentRes, DocumentsFilters } from 'src/app/models';

export const getFilteredDocumentsAction = createAction(
    '[Documents] Get documents' , 
    props<{ documentsFilters : DocumentsFilters }>()
);

export const getDocumentsSuccess = createAction(
    '[Documents] Get documents success',
    props<{ myDocumentRes : DocumentRes }>()
)

export const getDocumentsFailure = createAction(
    '[Documents] Get documents failure',
    props<{ error: HttpErrorResponse }>()
);

export const modifyFavoriteProperty = createAction(
    '[Documents] Modify favority property',
    props<{ params : { Id : string , favorite : boolean } }>()
)

export const addCommentsToDocuments = createAction(
    '[Documents] Add Comments to document',
    props<{ documentId : string }>()
)

export const addCommentsToDocumentsSuccess = createAction(
    '[Documents] Add Comments to document success',
    props<{ myCommentsRes : CommentsResClass }>()
)

export const addCommentsToDocumentsFailure = createAction(
    '[Documents] Add Comments to document failure',
    props<{ error : HttpErrorResponse }>()
)

export const setLikePropertyTrue = createAction(
    '[Documents] modify like property in document to true',
    props<{ documentId : string }>()
)

export const setLikePropertyTrueSuccess = createAction(
    '[Documents] modify like property in document to true success',
    props<{ documentId ? : string }>()
)

export const setLikePropertyTrueFailure = createAction(
    '[Documents] modify like property in document failure',
    props<{ modifyLikePropertytError: { documentId : string , error : HttpErrorResponse } }>()
)

export const setLikePropertyFalse = createAction(
    '[Documents] modify like property in document',
    props<{ documentId : string }>()
)

export const setLikePropertyFalseSuccess = createAction(
    '[Documents] modify like property in document success',
    props<{ documentId ? : string }>()
)

export const setLikePropertyFalseFailure = createAction(
    '[Documents] modify like property in document failure',
    props<{ modifyLikePropertytError: { documentId : string , error : HttpErrorResponse } }>()
)

export const addFeaturePropertyToDocument = createAction(
    '[Documents] add featured property to document',
    props<{ documentId : string }>()
)

export const addFeaturePropertyToDocumentSuccess = createAction(
    '[Documents] add featured property to document success',
    props<{ documentId ? : string }>()
)

export const addFeaturePropertyToDocumentFailure = createAction(
    '[Documents] add featured property to document failure',
    props<{ modifyLikePropertytError: { documentId : string , error : HttpErrorResponse } }>()
)

export const removeFeaturePropertyToDocument = createAction(
    '[Documents] remove featured property to document',
    props<{ documentId : string }>()
)

export const removeFeaturePropertyToDocumentSuccess = createAction(
    '[Documents] remove featured property to document success',
    props<{ documentId ? : string }>()
)

export const removeFeaturePropertyToDocumentFailure = createAction(
    '[Documents] remove featured property to document failure',
    props<{ modifyLikePropertytError: { documentId : string , error : HttpErrorResponse } }>()
)

export const addSasUrlProperty = createAction(
    '[Documents] add SasUrl property',
    props<{ documentId : string }>()
)

export const addSasUrlPropertySuccess = createAction(
    '[Documents] add SasUrl property success',
    props<{ item? : Item }>()
)

export const addSasUrlPropertyFailure = createAction(
    '[Documents] add SasUrl property failure',
    props<{ error : HttpErrorResponse }>()
)