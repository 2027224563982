import { DocumentsCommentsEffects } from "./effects/documents/comments.effects";
import { DocumentsEffects } from "./effects/documents/documents.effects";
import { ProfessionalsJsonEffects } from "./effects/professionals/professionalsJson.effects";



export const metaEffects = [
    DocumentsEffects,
    DocumentsCommentsEffects,
    ProfessionalsJsonEffects
]