import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
// import { RoutesGuard } from './guards/routes.guard';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '', redirectTo:'documents' , pathMatch:'full'
  },
  {
    path:'documents',
    loadChildren:()=>import('./documents/documents.module').then(m=>m.DocumentsModule),
    /* canActivate:[RoutesGuard] */
    canActivate: [MsalGuard]
  },
  {
    path:'streaming',
    loadChildren:()=>import('./streaming/streaming.module').then( m => m.StreamingModule ),
    canActivate: [MsalGuard]
  }
];

// const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes , {
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' 
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
