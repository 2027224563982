import { UserInfo } from '../../models';
import { createReducer , on } from '@ngrx/store';
import { addProfessionalAvatar, addProfessionalLoggedIdToUser, addUser } from '../actions/user-actions';

export const initialUserEntries : UserInfo = {idToken:"",accessToken:"",id_user:"",username:""} ;

export const UserReducer = createReducer(
    initialUserEntries,
    //on(clearUser, _ => {}),

    on(addUser , (entries , User)=>{
        const entriesClone : UserInfo = User/* JSON.parse(JSON.stringify(entries)) */;
        //entriesClone.push(User);
        // entriesClone = User;
        return entriesClone;
    }),

    on( addProfessionalLoggedIdToUser , ( state , {professionalLoggedId} ) => {
         return { ...state , professionalLoggedId : professionalLoggedId };
    }),

    on( addProfessionalAvatar , ( state , { avatar } ) =>{
        
        /* console.log(avatar);
        console.log( state ); */
        return { ...state , avatar : avatar };
    })

    /* on(removeUser , (object) => {
        object.idToken="";
        object.accessToken="";
        object.id_user="";
        object.username="";
        const entriesClone : User = Object;
        return entriesClone;
    }) */

    /* ,

    on(returnUsers , (entries )=>{
        return entries;
    }) */
) 