import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ProfessionalsJSONRes } from 'src/app/models';

export const getProfessionalsJsonAction = createAction(
    '[Professionals] Get professionals json' , 
    props<{ professionalsFilters : object }>()
);

export const getProfessionalsJsonActionSuccess = createAction(
    '[Professionals] Get professionals json success',
    props<{ professionalsJSONres : ProfessionalsJSONRes }>()
)

export const getProfessionalsJsonActionFailure = createAction(
    '[Professionals] Get professionals json failure',
    props<{ error: HttpErrorResponse }>()
);

