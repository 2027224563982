import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
/* rxjs */
import { Observable } from 'rxjs';
import { CommentsResClass, CommentToAddClass } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor( 
    private http :                                              HttpClient  
  ) { }

  LoadCommentsByDocumentId( documentId : string ):Observable<CommentsResClass>{
    return this.http.get<CommentsResClass>(`${environment.mediaService.api.url}videos/${documentId}/comments`);
  }

  AddCommentToDocument( comment : CommentToAddClass ):Observable<{isSuccess:boolean , id:string}>{
    return this.http.post<{isSuccess:boolean , id:string}>(`${environment.mediaService.api.url}comments` , comment);
  }

  RemoveCommentFromDocument( commentId : string ):Observable<{isSuccess:boolean}>{
    return this.http.delete<{isSuccess:boolean}>(`${environment.mediaService.api.url}comments/${commentId}`)
  }
}
