import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatDatesService {

  VerifyGreaterThan(startDate : string , endDate : string):boolean{

    if(Date.parse(startDate) <= Date.parse(endDate)){
      return true;
    }else{
      return false;
    }
  }

  VerifyLessThanToday(date : string):boolean{
    const mydate = new Date(date);
    const today = new Date();
    if (mydate.getTime() < today.setHours(0,0,0,0)) {
      return false
    } else {
      return true;
    }
  }

  FormatPrescriptionDateYearMonthDay(date:string):string{
    const dateObj  = new Date(date);
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    return `${year}-${month}-${day}`;
  }

  ChangingSlashesByScores(date : string):Date{

    const dateParts = date.split("/");

    // month is 0-based, that's why we need dataParts[1] - 1
    const dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0])

    return dateObject;
  }

  FormatDateForTable(date:string):string {

    const [day, month, year] = date.split("-");

    const myDate = new Date(`${month}/${day}/${year}`);

    const newDay = myDate.getDate();
    const newMonth = myDate.getMonth() + 1;
    const newYear = myDate.getFullYear();

    const dateFormatted = `${newDay.toString().padStart(2, '0')}/${newMonth.toString().padStart(2, '0')}/${newYear}`;

    return dateFormatted;
  }

  FormatDateDayMonthYear(date:string):string{

    const dateObj  = new Date(date);
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    return day + "/" + month + "/" + year;
  }

  FormatDateInTime(date:string):string{
    const d = new Date( date ); 
    return d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds(); 
  }


  SumDaysToDate(date:string , days:number):string{
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate.toString();
  }

  ReturnCurDateLastYear():string{
    const d = new Date();
    d.setDate(d.getDate() - 365); // subtract 5 days
    return d.toISOString().split("T")[0]; // 2020-01-19
  }

  AddDaysToADate(date : string , days : number):string{
    const d = new Date( date );
    d.setDate(d.getDate() + days); 
    return d.toISOString().split("T")[0];
  }

  SubstractDaysToADate(date : string , days : number):string{
    const d = new Date( date );
    d.setDate(d.getDate() - days); 
    return d.toISOString().split("T")[0];
  }
}
