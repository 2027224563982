import { Injectable } from '@angular/core';
import { CommentToAddClass } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})

export class OrderResultsService {

  private getTime(date: Date | null | undefined): number {
    return date != null ? new Date(date).getTime() : 0;
  }

  ShortArrayByTimeDatePropertyDesc(
    myArray: CommentToAddClass[],
    property: keyof CommentToAddClass & (string | number | symbol)
  ): CommentToAddClass[] {
    return myArray.sort((a, b) => {
      // Asegúrate de que la propiedad exista y sea de tipo Date
      const dateA = this.getTime(a[property] as Date | null | undefined);
      const dateB = this.getTime(b[property] as Date | null | undefined);

      return dateB - dateA;
    });
  }
}
