import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentRes, DocumentSasUrlRes, DocumentsFilters } from 'src/app/models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  constructor(private http : HttpClient) {}
  

  LoadDocumentUrlByDocumentId( documentId : string ):Observable<DocumentSasUrlRes>{
    return this.http.get<DocumentSasUrlRes>(`${environment.mediaService.api.url}video/${documentId}/sasurl`);
  }

  setLikePropertyTrue( documentId : string ):Observable<{isSuccess:boolean}>{
    return this.http.post<{isSuccess:boolean}>(`${environment.mediaService.api.url}videos/${documentId}/like` , {});
  }

  setLikePropertyFalse( documentId : string ):Observable<{isSuccess:boolean}>{
    return this.http.delete<{isSuccess:boolean}>(`${environment.mediaService.api.url}videos/${documentId}/like` );
  }

  addFeaturePropertyToDocument( documentId : string ):Observable<{isSuccess:boolean}>{
    return this.http.post<{isSuccess:boolean}>(`${environment.mediaService.api.url}videos/${documentId}/featured` , {});
  }

  deleteFeaturePropertyToDocument( documentId : string ):Observable<{isSuccess:boolean}>{
    return this.http.delete<{isSuccess:boolean}>(`${environment.mediaService.api.url}videos/${documentId}/featured` );
  }

  LoadDocuments(params ? :DocumentsFilters):Observable<DocumentRes> {
    
    let url = "";
    url += environment.mediaService.api.url + 'search-video' + '?';
    if(params){
      if(params.clinicName){
        url += `clinicName=${params.clinicName}&`;
      }
      if(params.patientCode){
        url += `patientCode=${params.patientCode}&`;
      }
      if(params.from){
        const formatedDate = params.from;
        url += `from=${formatedDate}&`;
      }
      if(params.to){
        const formatedDate = params.to;
        url += `to=${formatedDate}&`;
      }
      if(params.doctorCode){
        url += `doctorCode=${params.doctorCode}&`;
      }
      if(params.doctorName){
        url += `doctorName=${params.doctorName}&`;
      }
      if(params.patientName){
        url += `patientName=${params.patientName}&`;
      }
      if(params.clinicCode){
        url += `clinicCode=${params.clinicCode}&`;
      }
      if(params.size){
        url += `size=${params.size}&`;
      }
      if(params.first){
        url += `first=${params.first}&`;
      }
      if(params.hour){
        url += `hour=${params.hour}&`;
      }
      if(params.onlyLikes){
        url += `onlyLikes=${params.onlyLikes}&`;
      }
      if(params.onlyFeatureds){
        url += `onlyFeatureds=${params.onlyFeatureds}&`;
      }
      if(params.onlyCommented){
        url += `onlyCommented=${params.onlyCommented}&`;
      }
      if(params.withComplications){
        url += `withComplications=${params.withComplications}&`;
      }
      if(params.RoomCode){
        url += `RoomCode=${params.RoomCode}&`;
      }
      if(params.RoomName){
        url += `RoomName=${params.RoomName}&`;
      }
      if(params.surgeryName){
        url += `surgeryName=${params.surgeryName}&`;
      }
      if(params.surgeryCode){
        url += `surgeryCode=${params.surgeryCode}&`;
      }
    } else {
      url += `size=10&`;
      url += `first=1&`;
    }
    if(url.slice(-1) === '&')url = url.replace(/.$/,"");
    return this.http.get<DocumentRes>(url)
  }
}


