import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CommentToAddClass } from 'src/app/models';

export const addCommentToDocument = createAction(
    '[Documents Comments] add comment to document' , 
    props<{ comment : CommentToAddClass }>()
);

export const addCommentToDocumentSuccess = createAction(
    '[Documents Comments] add comment to documents success',
    props<{ myCommentWithId ? : CommentToAddClass }>()
)

export const addCommentToDocumentFailure = createAction(
    '[Documents comments] add comment to documents failure',
    props<{ addCommentError: { documentId : string , error : HttpErrorResponse } }>()
);

export const removeCommentToDocument = createAction(
    '[Documents Comments] remove comment from document' , 
    props<{ comment : CommentToAddClass }>()
);

export const removeCommentToDocumentSuccess = createAction(
    '[Documents Comments] remove remove comment from document success',
    props<{ comment ? : CommentToAddClass }>()
)

export const removeCommentToDocumentFailure = createAction(
    '[Documents comments] remove remove comment from document failure',
    props<{ addCommentError: { documentId : string , error : HttpErrorResponse } }>()
);

