import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap , map } from 'rxjs/operators';
import { CommentsService } from 'src/app/documents/services';
import * as commentsActions from 'src/app/store/actions/documents/comments.actions';

@Injectable()

export class DocumentsCommentsEffects {

    constructor( 
        private action$ :                   Actions,
        private commentsService :           CommentsService
    ){}

    addCommentToDocument$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(commentsActions.addCommentToDocument),
            mergeMap(( action ) => {

                const { comment } = action || {};
                return this.commentsService.AddCommentToDocument( comment )
                .pipe(
                    map(( data ) =>{
                        if( data.isSuccess ){

                            const myCommentWithId = { ...comment , id :  data.id , avatar : action.comment.avatar }
                            

                            return commentsActions.addCommentToDocumentSuccess({ myCommentWithId });
                        }else{
                            return commentsActions.addCommentToDocumentSuccess({  });
                        }

                        
                    }),

                    catchError(error => {
                        const errorResponse : { documentId : string , error : HttpErrorResponse } = { documentId : action.comment.videoId , error }
                        return of( commentsActions.addCommentToDocumentFailure({ addCommentError : {...errorResponse , error : error} } ) )
                    })
                )
            })
        )
    })

    romoveCommentFromDocument$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(commentsActions.removeCommentToDocument),
            mergeMap(( action ) => {

                const { comment } = action || {};

                return this.commentsService.RemoveCommentFromDocument( comment.id )
                .pipe(
                    map(( data ) =>{

                        if(data.isSuccess){
                            return commentsActions.removeCommentToDocumentSuccess({ comment });
                        } else {
                            return commentsActions.removeCommentToDocumentSuccess({  })
                        }
                        
                    }),
                        
                    catchError(error => {
                        const errorResponse : { documentId : string , error : HttpErrorResponse } = { documentId : action.comment.videoId , error }
                        return of( commentsActions.addCommentToDocumentFailure({ addCommentError : {...errorResponse , error : error} } ) )
                    })
                )
            })
        )
    })
}
