import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of , mergeMap } from 'rxjs';
import { catchError , exhaustMap, map } from 'rxjs/operators';
import { CommentsService, DocumentsService } from 'src/app/documents/services';
import { CommentsResClass, DocumentRes } from 'src/app/models';
import * as documentsActions from 'src/app/store/actions/documents/documents.actions';

@Injectable()

export class DocumentsEffects {

    constructor( 
        private action$ :                   Actions,
        private documentsService :          DocumentsService,
        private commentsService:            CommentsService
    ){}

    loadDocuments$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType( documentsActions.getFilteredDocumentsAction ),
            exhaustMap(action =>
                {
                    return this.documentsService.LoadDocuments(action.documentsFilters)
                    .pipe(
                        map(( documentRes : DocumentRes ) => {
                            
                            const myDocumentRes = documentRes;
                           
                            
                            return documentsActions.getDocumentsSuccess ( {myDocumentRes} )
                        }),
                        catchError((error : HttpErrorResponse) => {
                            return of( documentsActions.getDocumentsFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

    loadCommentsByDocumentIds$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType( documentsActions.addCommentsToDocuments ),
            exhaustMap(action =>
                {
                    return this.commentsService.LoadCommentsByDocumentId(action.documentId)
                    .pipe(
                        map(( commentsRes : CommentsResClass ) => {
                           
                            const myCommentsRes : CommentsResClass = { ...commentsRes , documentId : action.documentId  }

                            return documentsActions.addCommentsToDocumentsSuccess ( {myCommentsRes} )
                        }),
                        catchError((error : HttpErrorResponse) => {
                            return of( documentsActions.addCommentsToDocumentsFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

    addSasUrlToDocument$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(documentsActions.addSasUrlProperty),
            mergeMap(( action ) => {

                const { documentId  } = action || {};
                
                return this.documentsService.LoadDocumentUrlByDocumentId( documentId )
                .pipe(
                    map(( data ) =>{
                        
                        if( data.item ){
                            const { item } = data || {};
                            return documentsActions.addSasUrlPropertySuccess({ item });
                        }else{
                            return documentsActions.addSasUrlPropertySuccess({  });
                        }

                        
                    }),

                    catchError(error => {
                        // const errorResponse : { documentId : string , error : HttpErrorResponse } = { documentId : action.comment.videoId , error }
                        return of( documentsActions.addSasUrlPropertyFailure({ error : error /* addCommentError : {...errorResponse , error : error} */ } ) )
                    })
                )
            })
        )
    })

    setLikePropertyTrue$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(documentsActions.setLikePropertyTrue),
            mergeMap(( action ) => {

                const { documentId } = action || {};
                return this.documentsService.setLikePropertyTrue( documentId )
                .pipe(
                    map(( data ) =>{
                        if( data.isSuccess ){
                            return documentsActions.setLikePropertyTrueSuccess({ documentId });
                        }else{
                            return documentsActions.setLikePropertyTrueSuccess({  });
                        }

                        
                    }),

                    catchError(error => {
                        const errorResponse : { documentId : string , error : HttpErrorResponse } = { documentId : documentId , error }
                        return of( documentsActions.setLikePropertyTrueFailure({ modifyLikePropertytError : {...errorResponse , error : error} } ) )
                    })
                )
            })
        )
    })

    setLikePropertyFalse$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(documentsActions.setLikePropertyFalse),
            mergeMap(( action ) => {

                const { documentId } = action || {};
                return this.documentsService.setLikePropertyFalse( documentId )
                .pipe(
                    map(( data ) =>{
                        
                        if( data.isSuccess ){
                            return documentsActions.setLikePropertyFalseSuccess({ documentId });
                        }else{
                            return documentsActions.setLikePropertyFalseSuccess({  });
                        }

                        
                    }),

                    catchError(error => {
                        const errorResponse : { documentId : string , error : HttpErrorResponse } = { documentId : documentId , error }
                        return of( documentsActions.setLikePropertyFalseFailure({ modifyLikePropertytError : {...errorResponse , error : error} } ) )
                    })
                )
            })
        )
    })

    addFeatureProperty$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(documentsActions.addFeaturePropertyToDocument),
            mergeMap(( action ) => {

                const { documentId } = action || {};
                return this.documentsService.addFeaturePropertyToDocument( documentId )
                .pipe(
                    map(( data ) =>{
                        if( data.isSuccess ){
                            return documentsActions.addFeaturePropertyToDocumentSuccess({ documentId });
                        }else{
                            return documentsActions.addFeaturePropertyToDocumentSuccess({  });
                        }

                        
                    }),

                    catchError(error => {
                        const errorResponse : { documentId : string , error : HttpErrorResponse } = { documentId : documentId , error }
                        return of( documentsActions.addFeaturePropertyToDocumentFailure({ modifyLikePropertytError : {...errorResponse , error : error} } ) )
                    })
                )
            })
        )
    })

    removeFeatureProperty$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(documentsActions.removeFeaturePropertyToDocument),
            mergeMap(( action ) => {

                const { documentId } = action || {};
                return this.documentsService.deleteFeaturePropertyToDocument( documentId )
                .pipe(
                    map(( data ) =>{
                        
                        if( data.isSuccess ){
                            return documentsActions.removeFeaturePropertyToDocumentSuccess({ documentId });
                        }else{
                            return documentsActions.removeFeaturePropertyToDocumentSuccess({  });
                        }

                        
                    }),

                    catchError(error => {
                        const errorResponse : { documentId : string , error : HttpErrorResponse } = { documentId : documentId , error }
                        return of( documentsActions.removeFeaturePropertyToDocumentFailure({ modifyLikePropertytError : {...errorResponse , error : error} } ) )
                    })
                )
            })
        )
    })
}
