import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "src/environments/environment";

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/* export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
  console.log(logLevel);
} */

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: environment.mediaService.clientId/* msal.clientId */,
        authority: environment.mediaService.authority/* msal.authority */,
        redirectUri: '/',
        postLogoutRedirectUri: '/'
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
      },
      system: {
        allowNativeBroker: false,
        allowRedirectInIframe: true,
        loggerOptions: {
          // loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: environment.mediaService.scopes/* msal.scopes */ /* ['user.read'] */
      }/* ,
      loginFailedRoute: '/login-failed' */
    };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
    /* protectedResourceMap.set(environment.mediaService.api.baseUrl , environment.mediaService.scopes); *///Old player Config
    protectedResourceMap.set(environment.mediaService.api.url , environment.mediaService.scopes);//player Config
    protectedResourceMap.set(environment.usersService.url , environment.usersService.scopes);//users Config
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
}