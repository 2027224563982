import { HttpErrorResponse } from "@angular/common/http";

export class CommentToAddClass {
    videoId:                   string = '';
    // userId:                    string = '';
    userName:                  string = '';
    userEmail:                 string = '';
    message:                   string = '';
    id:                        string = '';
    created:                   Date = new Date();
    date:                      string = '';
    hour:                      string = '';
    owned :                    boolean = false;
    error?:                    HttpErrorResponse;
    userCode:                  string = '';
    avatar:                    string = '';
}

export interface CommentAddedRes {
    item:                      CommentToAddClass;
    isSuccess:                 boolean;
    messages:                  Message[];
}

export class Message {
    level:                     number = 0;
    body:                      string = '';
}

export class CommentsResClass {
    isSuccess :                boolean = false;
    messages  :                Message[] = [ new Message() ];
    items     :                CommentToAddClass[] = [ new CommentToAddClass() ];
    documentId:                string = '';
}