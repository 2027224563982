import { CommentToAddClass } from "./commentClass.models";

export class DocumentRes {

    first:      number = 0;
    size:       number = 0;
    totalPages: number = 0;
    totalCount: number = 0;
    items :     Document[] = [];
    isSuccess:  boolean = false;
    messages?:  Message[] = [{ level : 0 }];
    action :    string = '';
}

export class Document {
    id:             string = '';
    intId?:         number = 0;
    name:           string = '';
    path:           string = '';
    extension:      string = '';
    comments?:      CommentToAddClass[] = [new CommentToAddClass()];
    complications?: string[]=[];
    totalComments:  number=0;
    date:           string = new Date().toString();
    hour:           string = '';
    duration:       string = '';
    recordingDate?: RecordingDate = {
        year:      0,
        month:     0,
        day:       0,
        dayOfWeek: 0,
        dayOfYear: 0,
        dayNumber: 0
    };
    recordingTime?: RecordingTime = {
        hour:        0,
        minute:      0,
        second:      0,
        millisecond: 0,
        ticks:       0
    };
    order:          number = 0;
    clinic:         Clinic = {
        code:       '',
        name:       ''
    };
    doctor:         Doctor = {
        code:       '',
        name:       '',
        avatar:     '',
    };
    patient:        Patient = {
        code:       '',
        name:       ''
    };
    room:           Room = {
        code:       '',
        name:       ''
    };
    surgery:        Clinic = {
        code:       '',
        name:       ''
    };
    sasUri?:        string = '';
    isLike:         boolean = false;
    isFeatured:     boolean = false;
}

export interface Clinic {
    code: string;
    name: string;
}

export interface Doctor {
    code: string;
    name: string;
    avatar?:string | null ;
}

export interface Patient {
    code?:string;
    name: string;
}

export interface Room {
    code?: string;
    name:  string;
}

export interface Surgery {
    code: string;
    name: string;
}

export interface RecordingDate {
    year:      number;
    month:     number;
    day:       number;
    dayOfWeek: number;
    dayOfYear: number;
    dayNumber: number;
}

export interface RecordingTime {
    hour:        number;
    minute:      number;
    second:      number;
    millisecond: number;
    ticks:       number;
}

export interface Message {
    level: number;
}

/* export interface CommentRes {
    items:[];
    isSuccess:boolean;
    documentId: string;
} */