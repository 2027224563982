/* export class VideoFilterObj {
    date         : string = '';
    patientId    : string = '';
    clinicName   : string = '';
    clinicId     : string = '';
    doctorId     : string = '';
    patientName  : string = '';
    doctorName   : string = '';
    size         : number = 0;
    first        : number = 0;
    search       : string = ''
} */

/* export interface VideoFilterObj{
    date ?        :string,
    patientId ?   :string,
    clinicName ?  :string,
    clinicId ?    :string,
    doctorId ?    :string,
    patientName ? :string,
    doctorName ?  :string,
    size ?        :number,
    first ?       :number,
    search ?      :string
  } */

export class DocumentsFilters {
    name?:                     string | null = '';
    clinicCode?:               string | null = '';
    clinicName?:               string | null = '';
    doctorCode?:               string | null = '';
    doctorName?:               string | null = '';
    patientCode?:              string | null = '';
    patientName?:              string | null = '';
    RoomCode?:                 string | null = '';
    RoomName?:                 string | null = '';
    surgeryCode?:              string | null = '';
    surgeryName?:              string | null = '';
    //date?:                     string | null = '';
    hour?:                     string | null = '';
    first?:                    number | null = 0;
    size?:                     number | null = 0;
    from?:                     string | null = '';
    to?:                       string | null = '';
    onlyLikes?:                boolean | null= false;
    onlyFeatureds?:            boolean | null= false;
    onlyCommented?:            boolean | null= false;
    withComplications?:        boolean | null= false;
}