import { Injectable } from '@angular/core';
import { Observable , Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableToVideoEditPopUpService {

  private subject = new Subject<string>();

  sendMessage( documentId : string /* videoToEdit : Video */) {
    this.subject.next(documentId);
  }

  clearMessages() {
    this.subject.next('');
  }

  onMessage(): Observable<string> {
    return this.subject.asObservable();
  }
}
