export class VideoFiltersCheckBoxes {
    fecha_intervencion  :boolean;
    formato  :           boolean;
    descripcion  :       boolean;
    doctor  :            boolean;
    centro  :            boolean;
    unidad_medica  :     boolean;
    ojo  :               boolean;
    tipo  :              boolean;
    codigo_paciente  :   boolean;
    id_clinica  :        boolean;
    id_doctor  :         boolean;
    nombre_paciente  :   boolean;
    nombre_doctor  :     boolean;
    constructor(
        fecha_intervencion  :boolean = false,
        formato  :           boolean = false,
        descripcion  :       boolean = false,
        doctor  :            boolean = false,
        centro  :            boolean = false,
        unidad_medica  :     boolean = false,
        ojo  :               boolean = false,
        tipo  :              boolean = false,
        codigo_paciente  :   boolean = false,
        id_clinica  :        boolean = false,
        id_doctor  :         boolean = false,
        nombre_paciente  :   boolean = false,
        nombre_doctor  :     boolean = false
    ) {
        this.fecha_intervencion  = fecha_intervencion;
        this.formato  = formato;           
        this.descripcion  = descripcion;       
        this.doctor  = doctor;            
        this.centro  = centro;            
        this.unidad_medica  = unidad_medica;     
        this.ojo  = ojo;               
        this.tipo  = tipo;              
        this.codigo_paciente  = codigo_paciente;   
        this.id_clinica  = id_clinica;        
        this.id_doctor  = id_doctor;         
        this.nombre_paciente  = nombre_paciente;   
        this.nombre_doctor  = nombre_doctor;          
    }
}