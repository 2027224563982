<!-- <app-auth (iframe)="RecibeFrame($event)" ></app-auth> 
<div class="body-container" >
 
  <div class="main_background" >
    <div class="app-container">
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
  </div>
</div> -->

<!-- <div>
            <fa-icon [icon]="faFolderOpen"></fa-icon>
            <span class="documents-gdocumental_span" >
                Player
            </span>
        </div> -->
<!-- <input type="color" name="" id=""> -->

<div  class="app_documents-banner " ><!-- row -->
  <div class=" col-lg-6 col-md-6 col-sm-6 col-xs-4 app_documents-logo_container" ><!-- app_documents-logo_container -->
    <img *ngIf="logged" class="app_documents-logo" src="../assets/logos/iris.png" alt="logo clinica baviera">
    
  </div>
  <div class=" col-lg-6 col-md-6 col-sm-6 col-xs-4 app_documents-auth-container " >
    
    <app-auth (isLogged)="isLogged($event)"  (iframe)="RecibeFrame($event)" >
    </app-auth>
  </div>
</div>

<div *ngIf="isViewer && logged" class="app-main-first-container" >
  <div class="app_main-side_menu_container" >
    <app-sidemenu></app-sidemenu>
  </div>
  <div *ngIf="isViewer" class="app-main-container">
  

    <div class="app_documents-top_container bg-white" >
      <div class="documents-player_tittle_container" >
          
          <div>
            <img 
              src="../assets/icons/youtube_black_png.png" 
              alt=" video icon "
              class="documents-video_img_container"  
            >
          </div>
          <span class="app_documents-player_span" >
            Player
          </span>
      </div>
      <div>
        <nav class="c-menu">

          <span
            class="c-menu__link" 
            [ngClass]="{ 'activeRoute' : activePath === '/documents' }"
            (click)="GotoPage('/documents')"
          >
            ARCHIVO
          </span>
  
        <span 
          class="c-menu__link" 
          (click)="GotoPage('/streaming')"
          [ngClass]="{ 'activeRoute' : activePath === '/streaming' }"
          style="display:flex;"
        >
          DIRECTOS
          
          <img 
            src="../assets/icons/live_red_circle.png" 
            alt=" video icon "
            class="app_documents-live_red_circle_icon"  
          >
          
          </span>
     
          
          
       </nav>
      </div>
  
      <!-- <div class="app_documents-top_menu_container" >
        <div  class="app_documents-top_menu_itens" >
          <a [routerLinkActiveOptions]="{ exact: false }" > Archivo </a>
        </div>
        <div  class="app_documents-top_menu_itens" >
          <a [routerLinkActiveOptions]="{ exact: false }" > Directos </a>
        </div>
      </div> -->
  
      <!-- <div class="documents-streaming_container" 
          
      >
          <a 
              class="documents-streaming_button" 
              href="https://ambitious-smoke-08001c903.3.azurestaticapps.net/"
          >
              <fa-icon class="documents-streaming_icon" [icon]="faVideo"></fa-icon>
              Streaming
          </a>
      </div> -->
    </div>
     
  
    <div class="app-router-container">
      
      <div>
        <router-outlet *ngIf="!isIframe"></router-outlet>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isViewer" >
  <div class="server_error-main_container" >
    <div class="server_error-secondary_container" >
        <div>
            <div>
                <img class="server_error-baviera_logo" src="../assets/logos/logo_baviera_optim.png" alt="logo clinica baviera">
            </div>

            <div>
                <h2>
                  Permisos Insuficientes
                </h2>
            </div>

            <div>
                <span>
                  Su usuario no tiene permisos para acceder a esta aplicación , contacte con el administrador 
                </span>
            </div>
        </div>
    </div>
</div>
</div>

<!-- <input type="color" name="" id=""> -->



