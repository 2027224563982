<div class="side_menu-main-container" >
    <div class="side_menu-first_menu" >

        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('prescriptions')"
        >
            <fa-icon [icon]="faPrescriptionBottleMedical"  ></fa-icon>
        </div>
        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('goingUp')"
        >
            <fa-icon [icon]="faGraduationCap"  ></fa-icon>
        </div>
        <div class="side_menu-items_container c-menu__link side_menu-active_path" routerLink="/documents" 
            (click)="GoToPage('player')"
        >
            <img class="side_menu-youtube_image side_menu-youtube_img_active" src="../../assets/icons/youtube-blue.png" alt="">
        </div>
        <!-- <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('user')"
        >
            <fa-icon [icon]="faUserDoctor"  ></fa-icon>
        </div>
        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('users')"
        >
            <fa-icon [icon]="faUsers"  ></fa-icon>
        </div>

        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('clinic')"
        >
            <fa-icon [icon]="faBuilding"  ></fa-icon>
        </div>

        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('legal')"
        >
            <fa-icon [icon]="faScaleBalanced"  ></fa-icon>
        </div>

        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('lists')"
        >
            <fa-icon [icon]="faList"  ></fa-icon>
        </div> -->
    </div>

    <!-- <div class="side_menu-second_menu" >
        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('bookReader')"
        >
            <fa-icon [icon]="faBookReader"  ></fa-icon>
        </div>
        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('appointments')"
        >
            <fa-icon [icon]="faCalendarDays"  ></fa-icon>
        </div>
        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('calls')"
        >
            <fa-icon [icon]="faPhoneFlip"  ></fa-icon>
        </div>
        
        
        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('charts')"
        >
            <fa-icon [icon]="faChartColumn"  ></fa-icon>
        </div>
        
    </div>

    <div class="side_menu-empty-menu" ></div>

    <div class="side_menu-fourth_container side_menu-bottom">
        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('settings')"
        >
            <fa-icon [icon]="faGear"  ></fa-icon>
        </div>
        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('questions')"
        >
            <fa-icon [icon]="faCircleQuestion"  ></fa-icon>
        </div>
        <div class="side_menu-items_container c-menu__link" 
            (click)="GoToPage('show_more')"
        >
            <fa-icon [icon]="faAnglesRight"  ></fa-icon>
        </div>
    </div> -->
</div>