import { Injectable } from '@angular/core';
import { CommentToAddClass, ProfessionalFromJson, ProfessionalsJSONRes } from 'src/app/models';
import { FormatDatesService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class CommentsHelperService {

  constructor(
    private formatDatesService:                              FormatDatesService
  ) { }

  FormatDateAndTimeComment( comments : CommentToAddClass[] ): CommentToAddClass[]{

    const myFormattedComments = comments.map((c) => {
      return { ...c , date : this.formatDatesService.FormatDateDayMonthYear( c.created.toString() ) , hour : this.formatDatesService.FormatDateInTime( c.created.toString() ) };
    })

    return myFormattedComments

  }

  AddOwnedPropertyToRenderTrashIconWithEmail(comments : CommentToAddClass[] , userName : string | undefined):CommentToAddClass[]{

    return comments.map((c) => {

      return c.userEmail === userName ? { ...c , owned : true } : { ...c , owned : false }

    })
    
  }

  AddProfessionalPhoto( comments : CommentToAddClass[] , professionalsJson : ProfessionalsJSONRes ): /* void */ CommentToAddClass[]{

    return comments.map(( c : CommentToAddClass ) => {
      
      const proFound = professionalsJson.payload?.find((p) => p.email === c.userEmail);
      
      if( proFound ){
        
        const myAvatar : string | null = proFound.avatar;
        
        if(myAvatar){
          
          c.avatar = myAvatar;
        }

      } else {

        c.avatar = '';

      }

      return c; 
    })
  }

  AddAvatarByEmail( userEmail : string , professionalsJson : ProfessionalFromJson[] ): string {
    //test
    // const email = 'alvaro@optretina.com';

    const doctor = professionalsJson.filter((pj) => pj.email === userEmail);
    if(doctor){
      if(doctor?.length > 0){
        return doctor[0].avatar ? doctor[0].avatar : '';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}
