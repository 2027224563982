
export class DocumentSasUrlRes {
    item : Item           = { videoId : '' , sasUrl : '' }
}

/* export interface DocumentSASURLRes {
    item:      Item;
    isSuccess: boolean;
} */

export interface Item {
    videoId: string;
    sasUrl:  string;
}