
// import { SelectedClinicReducer } from "./reducers/prescriptions/selectedClinic.reducers";
// import { PatientsReducers } from "./reducers/prescriptions/patients.reducers";

import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { UserReducer } from "./reducers/user-reducers";
import { hydrationMetaReducer } from "./hydratation.reducer";
import { AppState } from "src/app/models";
import { DocumentsReducer } from "./reducers/documents/documents.reducers";
import { DocumentsFiltersReducer } from "./reducers/documents/documentsFilters.reducers";
import { ProfessionalsJsonReducer } from "./reducers/professionals/professionals-reducers";
// import { DocumentsReducer } from "./reducers/documents/documents.reducers";


export const reducers : ActionReducerMap<AppState> = {
  User : UserReducer,
  Documents: DocumentsReducer,
  DocumentsFilters: DocumentsFiltersReducer,
  professionalsJSON:ProfessionalsJsonReducer
}

export function debug(reducer: ActionReducer<string>): ActionReducer<string> {
  return function(state, action) {
    
 
    return reducer(state, action);
  };
}

export const metaReducers : MetaReducer[] = [hydrationMetaReducer]