import { createReducer , on } from '@ngrx/store';
import { DocumentsFilters } from 'src/app/models';
import { modifyDocumentsFilters } from '../../actions/documents/documentsFilters.actions';

export interface DocumentsFiltersState {
    prescriptionsFilters : DocumentsFilters
}

const initialState : DocumentsFilters = {
    from : '' /* SubstractDaysToADate(new Date().toDateString() , 0) */,
    first: 1,
    size: 10, 
    onlyFeatureds: false,
    onlyLikes:     false
};

export const DocumentsFiltersReducer = createReducer(
    initialState,
    on( modifyDocumentsFilters , (state , {documentsFilters}   ) => {

        let realFromDate = '';
        let realToDate = '';

        if( documentsFilters.from ){
            realFromDate = new Date(documentsFilters.from).getFullYear() + '-' + ( new Date(documentsFilters.from).getMonth() + 1 ) + '-' + new Date( documentsFilters.from ).getDate();  
        }

        if( documentsFilters.to ){
            realToDate = new Date(documentsFilters.to).getFullYear() + '-' + ( new Date(documentsFilters.to).getMonth() + 1 ) + '-' + new Date( documentsFilters.to ).getDate();  
        }

        
        return {...state , ...documentsFilters , from : realFromDate , to : realToDate};
    })
)

/* function SubstractDaysToADate(date : string , days : number):string{
    const d = new Date( date );
    d.setDate(d.getDate() - days); 
    // d.setDate(d.getMonth() + 1); 
    return d.toISOString().split("T")[0];
} */



