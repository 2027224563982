import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* msal */
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor, MsalService, MSAL_INTERCEPTOR_CONFIG, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MsalBroadcastService } from '@azure/msal-angular'; 
import * as msalConfigs from 'src/app/msal.configs';

/* angular-material */
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
/* redux */
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/* devexpress */
import { DxButtonModule ,DxCheckBoxModule } from 'devextreme-angular';

/* interceptor */
import { AuthComponent } from './auth/auth.component';
import { ProfileComponent } from './profile/profile.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';

/* fontawsome */
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { reducers , metaReducers } from './store/Metareducers';
import { EffectsModule } from '@ngrx/effects';
import { metaEffects } from './store/MetaEffects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ProfileComponent,
    SidemenuComponent,
    // TablePaginationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    /* devexpress */
    DxButtonModule,
    FontAwesomeModule,
    DxCheckBoxModule,
    /* material angular */
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    /* msal */
    MsalModule/* .forRoot( clientApplication , guardConfiguration  , interceptiorConfiguration) */,
    StoreModule.forRoot(reducers , {metaReducers}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
    }),
    EffectsModule.forRoot(metaEffects),
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [

    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalConfigs.MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalConfigs.MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalConfigs.MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService

    /* {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }, */
    /* {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    }, */
    /* MsalGuard,
    MsalService,
    
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpinterceptorsService,
      multi: true
    } */
    /*  */

    /* {
      provide: HTTP_INTERCEPTORS, 
      useClass: MsalInterceptor,
      multi: true
    }, 
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalGuard,
    MsalService,

    ,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpinterceptorsService,
      multi: true
    }  */
  ],
  bootstrap: [AppComponent , MsalRedirectComponent]
})
export class AppModule {
  /* constructor(library: FaIconLibrary){
    library.addIconPacks(fas);
    library.addIcons(faFolder)
  } */
}
